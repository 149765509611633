import React, { useState, useEffect } from "react"
import { PageContainer, PageFooter } from "../assemblies/pageTemplate"

import { MainNav } from "../assemblies/nav"
import { checkOptOut, setOptOut } from "../util"
import { Button } from "../elements/button"
import { GUTTER } from "../layout/util"
import { Stack } from "../layout/stack"
import { PageTitle } from "../assemblies/pageHeader"

const PrivacyWidget: React.FC = () => {
  const [localOptOut, setLocalOptOut] = useState<boolean | null>(null)
  useEffect(() => {
    setLocalOptOut(checkOptOut())
  }, [])

  function toggleOptOut() {
    setOptOut(!localOptOut)
    setLocalOptOut(checkOptOut())
  }

  return (
    <>
      <p>
        You currently <b>{localOptOut ? "do not allow" : "allow"}</b> the
        collection of analytics
      </p>
      <Button onClick={toggleOptOut} p={1}>
        {localOptOut ? "Allow" : "Block"} analytics
      </Button>
    </>
  )
}

const PrivacyPage = () => (
  <PageContainer>
    <MainNav className="wide" />
    <Stack gap={GUTTER} p={GUTTER}>
      <PageTitle title="Privacy Settings" />
      <PrivacyWidget />
    </Stack>
    <PageFooter />
  </PageContainer>
)
export default PrivacyPage
