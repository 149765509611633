import styled from "styled-components"
import { Link } from "gatsby"
import { PaddingProps } from "../layout/util"
import { padStyle } from "../layout/box"

type NavButtonProps = typeof Link & PaddingProps

export const NavButton = styled<NavButtonProps>(Link)`
  background-color: var(--color-gray-light);
  font-family: var(--font-title);
  font-weight: 600;
  color: var(--color-dark);
  border-radius: var(--s-3);
  display: block;
  ${padStyle}

  &:hover {
    opacity: 0.8;
  }
`

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & PaddingProps

export const Button = styled.button<ButtonProps>`
  background-color: var(--color-primary);
  font-family: var(--font-title);
  font-weight: 600;
  color: var(--color-light);
  border-radius: var(--s-3);
  display: block;
  cursor: pointer;
  ${padStyle}

  &:hover {
    background-color: var(--color-secondary);
  }
`
